import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/heritage/Home.vue';
import StreamersView from '../views/heritage/StreamersView.vue'; 
import UsersView from '../views/heritage/UsersView.vue'; 
import RightholdersView from '../views/heritage/RightholdersView.vue'; 
import NewsView from '../views/heritage/NewsView.vue'; 
import WrongLoginView from '../views/heritage/WrongLoginView.vue';
import Profile from '../views/heritage/Profile.vue';
import Company from '../views/heritage/Company.vue';
import NewsDetails from '../views/heritage/NewsDetails.vue';
import FilmView from '../views/heritage/FilmView.vue';
import SearchView from '../views/heritage/SearchView.vue';
import ScreeningRoomView from '../views/heritage/ScreeningRoomView.vue';
import ProfileEditView from '../views/heritage/ProfileEditView.vue';
import FilmEditView from '../views/heritage/FilmEditView.vue';
import PrivacyEditView from '../views/heritage/PrivacyEditView.vue';
import CompanyEditView from '../views/heritage/CompanyEditView.vue';
import CompleteProfileView from '../views/heritage/CompleteProfileView.vue';
import AccreditationView from '../views/heritage/AccreditationView.vue';
import stepAwayHeritage from '../views/heritage/stepAwayHeritage.vue';
import stepAwayHeritagePay from '../views/heritage/stepAwayHeritagePay.vue';
import stepAwayHeritagePostLogin from '../views/heritage/stepAwayHeritagePostLogin.vue';
import stepAwayHeritageDemo from '../views/heritage/stepAwayHeritageDemo.vue';
import stepAwayReview from '../views/heritage/stepAwayReview.vue';
import SplashDevView from '../views/SplashDevView.vue';
import WalkthroughView from '../views/heritage/WalkthroughView.vue';
import PageView from '../views/PageView.vue';
import ModalView from '../views/ModalView.vue';
import CompleteInvite from '../views/heritage/CompleteInvite.vue'

import PageNotFound from '../views/heritage/PageNotFound.vue';
import PaymentComplete from '../views/heritage/PaymentComplete.vue';
import { Store } from 'vuex';
import { RootState } from '@/store/modules/root';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/auth';
import { permitAuthenticatedUser as permitAuthenticated, LOGIN_PATH, goToIntentionOrHome } from './guard';
import RootLayout from '../views/heritage/RootLayout.vue';
import HeaderContainer from '../components/heritage/containers/HeaderContainer.vue';
import FooterContainer from '../components/heritage/containers/FooterContainer.vue';
import { RoutePropsFunction } from 'vue-router/types/router';
import { AUTH_CALLBACK_PATH } from '@/service/auth';
import UsersModule from '@/store/modules/users';
import { HERITAGE } from '@/service/site';
import ValuesModule from '@/store/modules/values';
import NotificationModule from '@/store/modules/notifications';
import { nextTick } from 'vue/types/umd';
import { UserResponse } from '@/client/user.client';

Vue.use(VueRouter);

export const HOME_PATH = '/';
export const STEP_AWAY = '/step-away';
export const STEP_AWAY_LOGIN = '/step-away-login';
export const STEP_AWAY_PAY = '/step-away-pay';
export const STEP_AWAY_DEMO = '/step-away-demo';
export const STEP_AWAY_REVIEW = '/step-away-review';
export const PROFILE_PATH = '/profile';
export const COMPANY_PATH = '/company';
export const FILM_FORM_PATH = '/films/form';
export const FILM_PATH = '/films';
export const COMPLETE_PROFILE = '/complete-profile';
export const NEWS_PATH = '/news';
export const SCREENING_ROOM_LIST = '/screeningroom';
export const USER_LIST = '/users';
export const STREAMERS_LIST = '/streamers';
export const RIGHTHOLDER_LIST = '/rightholders';

function toDefaultView(props: RoutePropsFunction) {
  return {default: props};
}

function createHeritageRouter(store: Store<RootState>) {
  const authModule = getModule(AuthModule, store);
  const usersModule = getModule(UsersModule, store);
  const valuesModule = getModule(ValuesModule, store);
  const notificationModule = getModule(NotificationModule, store);
  return new VueRouter({
    mode: 'history',
    routes: [
      {
        path: AUTH_CALLBACK_PATH,
        name: 'AuthCallback',
        beforeEnter: async (to, from, next) => {
          const token = to.query.boxToken as string | null;
          if (!token) {
            return next(LOGIN_PATH);
          }

          await authModule.setAuthToken(token);
          const me = await usersModule.dispatchGetMe();
          console.log(me)
          if(usersModule.heritageFull) {
            goToIntentionOrHome(next)
          } else if(usersModule.paymentLink) {
            next(STEP_AWAY_PAY)
          } else {
            next(HOME_PATH)
          }
        
        },
      },
      {
        path: HOME_PATH,
        name: 'Root',
        components: {
          default: RootLayout,
          header: HeaderContainer,
          footer: FooterContainer,
        },
        children: [
          {
            path: HOME_PATH,
            name: 'Home',
            components: {
              default: Home,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: STEP_AWAY,
            name: 'stepAwayHeritage',
            components: {
              default: stepAwayHeritage,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: STEP_AWAY_PAY,
            name: 'stepAwayHeritagePay',
            components: {
              default: stepAwayHeritagePay,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: STEP_AWAY_LOGIN,
            name: 'stepAwayHeritagePostLogin',
            components: {
              default: stepAwayHeritagePostLogin,
            },
            beforeEnter() {
              location.href = window.origin;
            },
            //beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: STEP_AWAY_DEMO,
            name: 'stepAwayHeritageDemo',
            components: {
              default: stepAwayHeritageDemo,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: STEP_AWAY_REVIEW,
            name: 'stepAwayHeritageReview',
            components: {
              default: stepAwayReview,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: STREAMERS_LIST,
            name: 'StreamersView',
            components: {
              default: StreamersView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: USER_LIST,
            name: 'UsersView',
            components: {
              default: UsersView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: SCREENING_ROOM_LIST,
            name: 'ScreeningRoomView',
            components: {
              default: ScreeningRoomView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: RIGHTHOLDER_LIST,
            name: 'RightholdersView',
            components: {
              default: RightholdersView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: `${NEWS_PATH}`, 
            name: 'NewsView',
            components: {
              default: NewsView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: `${NEWS_PATH}/:id`, 
            name: 'NewsDetails',
            components: {
              default: NewsDetails,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: 'none'
            })),
          },
          {
            path: `${PROFILE_PATH}/:id/privacy-settings`,
            name: 'Privacy settings',
            components: {
              default: PrivacyEditView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${PROFILE_PATH}/accreditation/:id`, 
            name: 'Accreditation',
            components: {
              default: AccreditationView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: 'none'
            })),
          },
          {
            path: `${PROFILE_PATH}/:id`, 
            name: 'Profile',
            components: {
              default: Profile,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: 'none'
            })),
          },
          {
            path: `${PROFILE_PATH}/:id/edit`,
            name: 'Profile Edit',
            components: {
              default: ProfileEditView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${PROFILE_PATH}/:id/:chapter`,
            name: 'Profile Chapter',
            components: {
              default: Profile,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: route.params.chapter
            })),
          },
          {
            path: FILM_FORM_PATH,
            name: 'FilmNewView',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            components: {
              default: FilmEditView,
            },
            props: toDefaultView(() => ({
              id: null,
            })),
          },
          {
            path: `${FILM_FORM_PATH}/:id`,
            name: 'FilmEditView',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            components: {
              default: FilmEditView,
            },
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${FILM_PATH}/:id`,
            name: 'Film',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            components: {
              default: FilmView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${COMPANY_PATH}/:id/edit`,
            name: 'Company Profile Edit',
            components: {
              default: CompanyEditView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${COMPANY_PATH}/:id`, 
            name: 'Company',
            components: {
              default: Company,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: "none"
            })),
          },
          {
            path: `${COMPANY_PATH}/:id/:chapter`,
            name: 'Company Chapter',
            components: {
              default: Company,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: route.params.chapter
            })),
          },
          {
            path: `search/:words`,
            name: 'Search',
            components: {
              default: SearchView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              words: route.params.words,
              chapter: 'any'
            })),
          },
          {
            path: `search/:words/:chapter`,
            name: 'Search',
            components: {
              default: SearchView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              words: route.params.words,
              chapter: route.params.chapter
            })),
          },
          {
            path: `walkthrough`,
            name: 'Walkthrough',
            components: {
              default: WalkthroughView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE)
          },
          //Page management
          {
            path: '/page/:id/:slug',
            name: 'Page',
            components: {
              default: PageView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              slug: route.params.slug
            })),
          },
          {
            path: '/modal/:id/:slug',
            name: 'Modal',
            components: {
              default: ModalView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              slug: route.params.slug
            })),
          },
          {
            path: LOGIN_PATH,
            name: 'Login',
            beforeEnter() {
              location.href = window.origin + "/splash";
            },
          },
          {
            path: '/splash',
            name: 'Splash DEV',
            component: SplashDevView,
          },
          {
            path: '/wrong-login',
            name: 'WrongLogin',
            component: WrongLoginView,
          },
          {
            path: '/payment-complete',
            name: 'PaymentComplete',
            components: {
              default: PaymentComplete,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          },
          {
            path: '/complete-invitation/:token',
            name: 'CompleteInvite',
            components: {
              default: CompleteInvite,
            },
            props: toDefaultView((route) => ({
              token: route.params.token
            })),
          },
          {
            path: '/register',
            name: 'Register',
            async beforeEnter() {
              let me:UserResponse | null = null;
              try{
                me = await usersModule.dispatchGetMe();
              } catch(e) {}
              if(me) {
                location.href = window.origin + "/profile/accreditation/"+me.id;
              } else {
                location.href = window.origin + "/splash"
              }
            },
            //component: WrongLoginView, TODO create component with registration info
          },
          { name: 'heritageCheck', path: "heritageHack", component: PageNotFound}, // TODO Remove
          {
            path: '*', // For second release
            name: 'PageNotFound',
            components: {
              default: PageNotFound,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,HERITAGE),
          }
        ]
      }
    ],
  });
}

export default createHeritageRouter;