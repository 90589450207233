import 'reflect-metadata';
import createHeritageRouter from "./router/heritage";
import store from './store';
import bootstrap from './bootstrap';
import container from './ioc/inversify.config';
import TYPES from './ioc/types';
import { Site, Heritage } from './env/site'

const router = createHeritageRouter(store);

container.bind<Site>(TYPES.Site).to(Heritage)

bootstrap(router);
