import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Profile from '../views/Profile.vue';
import FilmView from '../views/FilmView.vue';
import SearchView from '../views/SearchView.vue';
import ResourcesView from '../views/ResourcesView.vue';
import ResourceView from '../views/ResourceView.vue';
import LabelsView from '../views/LabelsView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import AuthorView from '../views/AuthorView.vue';
import FilmEditView from '../views/FilmEditView.vue';
import ProfileEditView from '../views/ProfileEditView.vue';
import PrivacyEditView from '../views/PrivacyEditView.vue';
import CompleteProfileView from '../views/CompleteProfileView.vue';
import SuggestResourceView from '../views/SuggestResourceView.vue';
import VideoJSView from '../views/VideoJSView.vue';
import DemoLunr from '../views/examples/DemoLunr.vue';
import DemoNotification from '../views/examples/DemoNotification.vue';
import PageNotFound from '../views/PageNotFound.vue';
import { Store } from 'vuex';
import { RootState } from '@/store/modules/root';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/auth';
import { permitAuthenticatedUser as permitAuthenticated, LOGIN_PATH, goToIntentionOrHome } from './guard';
import RootLayout from '../views/RootLayout.vue';
import HeaderContainer from '../components/containers/HeaderContainer.vue';
import FooterContainer from '../components/containers/FooterContainer.vue';
import { RoutePropsFunction, Route } from 'vue-router/types/router';
import { AUTH_CALLBACK_PATH } from '@/service/auth';
import UsersModule from '@/store/modules/users';
import { TOOLBOX } from '@/service/site';
import ValuesModule from '@/store/modules/values';

import ForumView from '../views/ForumView.vue';
import ForumsView from '../views/ForumsView.vue';
import TopicView from '../views/TopicView.vue';
import UsersView from '../views/UsersView.vue';
import SuggestForumCategoryView from '../views/SuggestForumCategoryView.vue';
import ForumNewTopicView from '../views/ForumNewTopicView.vue';
import NotificationModule from '@/store/modules/notifications';

import PageView from '../views/PageView.vue';

Vue.use(VueRouter);

export const HOME_PATH = '/';
export const COMPLETE_PROFILE = '/complete-profile';

export const REGISTER_PATH = '/register';
export const AUTHOR_PATH = '/author';
export const PROFILE_PATH = '/profile';
export const FILM_FORM_PATH = '/films/form';
export const FILM_PATH = '/films';
export const USER_PATH = '/members';

export const RESOURCES_PATH = '/resources';
export const FORUM_PATH = '/forum';

export function getFilmPath(id: number) {
  return `${FILM_PATH}/${id}`;
}

export function getFilmFormPath(id: number) {
  return `${FILM_FORM_PATH}/${id}`;
}

export function getAuthorPath(id: number) {
  return `${AUTHOR_PATH}/${id}`;
};

export function getProfilePath(id: number) {
  return `${PROFILE_PATH}/${id}`;
}

function toDefaultView(props: RoutePropsFunction) {
  return {default: props};
}

function parseNumberList(rawCategories: string | (string | null)[]): number[] | null {
  if (rawCategories === "") {
    return null;
  }
  let categories: number[] | null = null;
  if (rawCategories instanceof Array) {
    categories = rawCategories
      .filter(id => !!id)
      .map(id => +(id as string));
  } else if (rawCategories === undefined) {
    categories = null;
  } else {
    categories = (rawCategories || '').split(',').map(id => +id)
  }
  return categories;
}

function parseStringList(rawCapabilities: string | (string | null)[]): string[] | null {
  if (rawCapabilities === "") {
    return null;
  }
  let capabilities: string[] | null = null;
  if (rawCapabilities instanceof Array) {
    capabilities = rawCapabilities
      .filter(id => !!id) as string[];
  } else if (rawCapabilities === undefined) {
    capabilities = null;
  } else {
    capabilities = (rawCapabilities || '').split(',')
  }
  return capabilities;
}
function createToolboxRouter(store: Store<RootState>) {
  const authModule = getModule(AuthModule, store);
  const usersModule = getModule(UsersModule, store);
  const valuesModule = getModule(ValuesModule, store);
  const notificationModule = getModule(NotificationModule, store);
  return new VueRouter({
    mode: 'history',
    routes: [
      {
        path: AUTH_CALLBACK_PATH,
        name: 'AuthCallback',
        beforeEnter: async (to, from, next) => {
          const token = to.query.boxToken as string | null;
          if (!token) {
            next(LOGIN_PATH);
          }

          await authModule.setAuthToken(token);
          await usersModule.dispatchGetMe()

          if(usersModule.profileComplete) {
            goToIntentionOrHome(next)
          } else {
            next(COMPLETE_PROFILE);
          }
          
        },
      },
      {
        path: HOME_PATH,
        name: 'Root',
        components: {
          default: RootLayout,
          header: HeaderContainer,
          footer: FooterContainer,
        },
        children: [
          {
            path: HOME_PATH,
            name: 'Home',
            components: {
              default: Home,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
          },
          {
            path: RESOURCES_PATH,
            name: 'Resources',
            components: {
              default: ResourcesView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => {
              const categories = parseNumberList(route.query.categories);
              const capabilities = parseStringList(route.query.capabilities);
              const tags = parseStringList(route.query.tags);
              let sort = parseStringList(route.query.sort);
      
              if(!sort) sort = ['-pubblication_date']

              return {
                categories,
                capabilities,
                tags,
                sort,
              }
            })
          },
          {
            path: '/resource/:id',
            name: 'Resource',
            components: {
              default: ResourceView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: '/resource/:id/:type/:id_parent',
            name: 'Resource',
            components: {
              default: ResourceView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              type: +route.params.type,
              id_parent: +route.params.id_parent,
            })),
          },
          {
            path: '/resource/:id/:type/:id_parent/:id_comment',
            name: 'Resource',
            components: {
              default: ResourceView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              type: +route.params.type,
              id_parent: +route.params.id_parent,
              id_comment: +route.params.id_comment,
            })),
          },
          {
            path: FORUM_PATH,
            name: 'Forums',
            components: {
              default: ForumsView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => {
              const categories = parseNumberList(route.query.categories);
              const capabilities = parseStringList(route.query.capabilities);
              const tags = parseStringList(route.query.tags);
              let sort = parseStringList(route.query.sort);
      
              if(!sort) sort = ['-pubblication_date']

              return {
                categories,
                capabilities,
                tags,
                sort,
              }
            })
          },
          {
            path: '/forum/:id',
            name: 'Forum',
            components: {
              default: ForumView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => {
              const id = +route.params.id;
              let sort = parseStringList(route.query.sort);
      
              if(!sort) sort = ['-pubblication_date']

              return {
                id,
                sort,
              }
            })
          },
          {
            path: '/forum/:category/:id',
            name: 'Topic',
            components: {
              default: TopicView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              category: +route.params.category,
              id: +route.params.id,
              chapter: 'none'
            })),
          },
          {
            path: '/forum/:category/:id/:type/:id_parent',
            name: 'Topic',
            components: {
              default: TopicView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              category: +route.params.category,
              id: +route.params.id,
              type: +route.params.type,
              id_parent: +route.params.id_parent,
              chapter: 'none'
            })),
          },
          {
            path: '/forum/:category/:id/:type/:id_parent/:id_comment',
            name: 'Topic',
            components: {
              default: TopicView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              category: +route.params.category,
              id: +route.params.id,
              type: +route.params.type,
              id_parent: +route.params.id_parent,
              id_comment: +route.params.id_comment,
              chapter: 'none'
            })),
          },
          {
            path: '/suggest-forum-category',
            name: 'Suggest forum category',
            components: {
              default: SuggestForumCategoryView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView(() => ({
              id: null,
            })),
          },
          {
            path: '/new-topic/:category',
            name: 'New Topic',
            components: {
              default: ForumNewTopicView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              category: +route.params.category,
              id: null,
            })),
          },
          {
            path: USER_PATH,
            name: 'UsersView',
            components: {
              default: UsersView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
          },
          //Page management
          {
            path: '/page/:id/:slug',
            name: 'Page',
            components: {
              default: PageView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              slug: route.params.slug
            })),
          },
          {
            path: `${PROFILE_PATH}/:id`, 
            name: 'Profile',
            components: {
              default: Profile,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: 'none'
            })),
          },
          {
            path: `${PROFILE_PATH}/:id/edit`,
            name: 'Profile Edit',
            components: {
              default: ProfileEditView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${PROFILE_PATH}/:id/settings`,
            name: 'Settings',
            components: {
              default: PrivacyEditView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${PROFILE_PATH}/:id/:chapter`,
            name: 'Profile Chapter',
            components: {
              default: Profile,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
              chapter: route.params.chapter
            })),
          },
          
          {
            path: '/suggest-resource',
            name: 'Suggest resource',
            components: {
              default: SuggestResourceView,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView(() => ({
              id: null,
            })),
          },
          {
            path: `${AUTHOR_PATH}/:id`,
            name: 'Author',
            components: {
              default: AuthorView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: FILM_FORM_PATH,
            name: 'FilmNewView',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            components: {
              default: FilmEditView,
            },
            props: toDefaultView(() => ({
              id: null,
            })),
          },
          {
            path: `${FILM_FORM_PATH}/:id`,
            name: 'FilmEditView',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            components: {
              default: FilmEditView,
            },
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: `${FILM_PATH}/:id`,
            name: 'Film',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            components: {
              default: FilmView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            props: toDefaultView((route) => ({
              id: +route.params.id,
            })),
          },
          {
            path: LOGIN_PATH,
            name: 'Login',
            component: LoginView,
          },
          {
            path: COMPLETE_PROFILE,
            name: 'Complete profile',
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            component: CompleteProfileView,
          },
          {
            path: REGISTER_PATH,
            name: 'Register',
            component: RegisterView,
          },
          {
            path: `search/:words`,
            name: 'Search',
            components: {
              default: SearchView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              words: route.params.words,
              chapter: 'any'
            })),
          },
          {
            path: `search/:words/:chapter`,
            name: 'Search',
            components: {
              default: SearchView,
              header: HeaderContainer,
              footer: FooterContainer,
            },
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
            props: toDefaultView((route) => ({
              words: route.params.words,
              chapter: route.params.chapter
            })),
          },
          {
            path: '/test-video',
            name: 'VideoJS',
            component: VideoJSView,
          },
          {
            path: '/test-lunr',
            name: 'TestLunr',
            component: DemoLunr,
          },
          {
            path: '/test-notification',
            name: 'TestNotification',
            component: DemoNotification,
            beforeEnter: permitAuthenticated(authModule,usersModule,valuesModule,notificationModule,TOOLBOX),
          },
          { path: "*", component: PageNotFound}
        ]
      }
    ],
  });
}

export default createToolboxRouter;
