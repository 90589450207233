import { render, staticRenderFns } from "./DemoNotification.vue?vue&type=template&id=1e38d608&"
import script from "./DemoNotification.vue?vue&type=script&lang=ts&"
export * from "./DemoNotification.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports